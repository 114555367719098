body {
padding-bottom: 250px;
}

.main {
    max-width: 360px;
    margin: 0 auto;
    padding: 15px
}

.title {
    text-align:center;
    margin-top: 0;
}

.form { 
    input{
        max-width: 28%;
        text-align: center;
        padding: 8px 0;
    }

    table, table input {
        width: 100%;
        max-width: 100%;
    }
}

.tyre {
    display: flex;
    align-items: center;
    justify-content: center;
}

.divider {
    margin:0 6px;
}

.rpm {
    width: 100%;
    max-width: 100% !important;
}

.result {
    min-height: 70px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wheel_height {
    text-align: center;
    border: 1px solid black;
}

.save-history {
    margin: 22px 0;

    button {
        width: 100%;
    }
}

.history-table {
    ul {
        list-style: none;
        padding-left: 0;

        li {
            padding: 20px 0;
            border-bottom: 1px solid black;
        }

        li:last-child {
            border-bottom: none;
        }

        .history-item-date {
            margin-bottom: 8px;
            margin-top: 0;
            font-size: 18px;
        }
    }

    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    table {
        width: 100%;
        font-size: 18px;
    }

    td {
        padding: 5px;
    }
}